<template>
  <div class="index">
    <div data-server-rendered="true" id="__nuxt">
      <!---->
      <div id="__layout">
        <div class="full-wrap">
         <headerCom :isactive="isactive" ></headerCom>
         <!-- <img src="../../assets/images/header_top.png" alt=""> -->
         <div class="header_bg"></div>
		 <div class="pd-lr80">
			 <img src="../../assets/images/7.png" alt="" class="img-map">
		 </div>
           <div class="footer-img">
                  <div class="pdt-20">
                    <a href="https://beian.miit.gov.cn/" target="blank">【工信部ICP备案】</a>
                    <a href="https://beian.miit.gov.cn/" target="blank">渝ICP备2021012534号</a>
                  </div>
                </div>
        </div>
      </div>
    </div>
  </div>
</template>
npm
<script>
import headerCom from "../../components/header-com.vue"
export default {
  props: [],
  components: {
    headerCom
  },
  data() {
    return {
      isScroll: false,
      isactive:4
    };
  },
  mounted() {
    
  },
  methods: {
    
  },

};
</script>

<style lang="scss" scoped>
	.pd-lr80{
		padding-left: 80px;
		padding-right: 80px;
	}
.header_bg{
  widows: 100%;
  height: 700px;
  background-image: url("../../assets/images/header_top.jpg");
  background-size: 100% 100%;
}
.img-map{
  min-height: 300px;
}
.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  background-color: #343535;
  width: 100%;
  height: 60px;
}
.map-box{
  padding-top: 80px;
  width: 80%;
  height: auto;
  margin: 0 auto;
}
.map-box img{
  width: 100%;
  height: auto;
}

.footer[data-v-38cb5c44] {
  width: 100%;
  height: auto;
  background: #061845;
  padding: 40px 0;
}

.footer .footer-wrap[data-v-38cb5c44] {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.footer .footer-wrap .footer-wrap-top[data-v-38cb5c44] {
  font-size: 16px;
  color: #fff;
  line-height: 22px;
}

.footer .footer-wrap .weixinNumber[data-v-38cb5c44] {
  display: inline-block;
  width: 144px;
  height: 144px;
  margin: 32px 0;
}

.footer .footer-wrap .footer-bottom[data-v-38cb5c44] {
  width: 51.8%;
  font-size: 16px;
  color: hsla(0, 0%, 100%, 0.6);
  line-height: 22px;
  margin: 0 auto 20px;
}

.footer .footer-wrap .footer-bottom .footer-left[data-v-38cb5c44] {
  float: left;
  overflow: hidden;
}

.footer .footer-wrap .footer-bottom .footer-right[data-v-38cb5c44] {
  float: right;
  overflow: hidden;
}

.footer .footer-wrap .footer-bottom .footer-right a[data-v-38cb5c44] {
  color: hsla(0, 0%, 100%, 0.6);
}

@media screen and (max-width: 1000px) {
  .footer[data-v-38cb5c44] {
    width: 100%;
    height: auto;
    background: #061845;
    padding: 40px 0;
  }

  .footer .footer-wrap[data-v-38cb5c44] {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
  }

  .footer .footer-wrap .footer-wrap-top[data-v-38cb5c44] {
    font-size: 16px;
    color: #fff;
    line-height: 22px;
  }

  .footer .footer-wrap .weixinNumber[data-v-38cb5c44] {
    display: inline-block;
    width: 144px;
    height: 144px;
    margin: 32px 0;
  }

  .footer .footer-wrap .footer-bottom[data-v-38cb5c44] {
    width: 92%;
    font-size: 12px;
    color: hsla(0, 0%, 100%, 0.6);
    line-height: 22px;
    margin: 0 auto 20px;
  }

  .footer .footer-wrap .footer-bottom .footer-left[data-v-38cb5c44],
  .footer .footer-wrap .footer-bottom .footer-right[data-v-38cb5c44] {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }
}
</style>
